import * as React from "react";
import Layout from "../../layout";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { RichText } from "prismic-reactjs";
import "./index.scss";

// markup
const PP = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPrivacyPolicy {
        nodes {
          data {
            privacy_policy {
              richText
            }
          }
        }
      }
    }
  `);

  const tc = get(data, "allPrismicPrivacyPolicy.nodes", []);

  const ppText = tc[0].data.privacy_policy.richText;

  return (
    <Layout
      title={"Refund Policy"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
      publicPage
    >
      <section className="privacy-policy register py-8 py-lg-16">
        <article className="container row px-8 mx-auto">
          <p>
            Thank you for purchasing CORSnet Subscription Services. If, for any
            reason, you have any concerns about the payments you made or
            subscription dates you ordered, we are here to help.
          </p>

          <h3>Refunds</h3>
          <p>
            Once you make a payment and Subscription login details are issued,
            the purchase can not be refunded. However, you can request to change
            the effective date of the Subscriotion you purchased.
          </p>

          <h3>Date Exchanges</h3>
          <p>
            If you would like to exchange your purchased Subscription Date, you
            can do so by contacting our customer support team 0772131310 or
            info@corsnet.lk
          </p>

          <h3>Processing Time</h3>
          <p>
            Refunds and exchanges will be processed within 3 business days after
            we receive your notice.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns regarding our refund policy,
            please contact our customer support team 0772131310 or
            info@corsnet.lk
          </p>
        </article>
      </section>
    </Layout>
  );
};

export default PP;
